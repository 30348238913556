import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import PageItem from "../components/pageItem"
import SEO from "../components/seo"
import Img from "gatsby-image"

const SecondPage = (props) => (
  <Layout pageInfo={{ pageName: "kohle" }}>
    <SEO title="kohle" />
    {/* <h1>Ather</h1> */}
    {/* <p>Welcome to page 2</p> */}
    {/* <Link to="/">Go back to the homepage</Link> */}
    <div id="content" className="pages-placeholder  horizontal-scroll-wrapper" 
    style={{width: `2300vw`, background:"#BBA4AC"}}
    >
      {/* <div className="pages"> */}

        <PageItem  ><Img fluid={props.data.kohle01.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.kohle02.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.kohle03.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.kohle04.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.kohle05.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.kohle06.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.kohle07.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.kohle08.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.kohle09.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.kohle10.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.kohle11.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.kohle12.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.kohle13.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.kohle14.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.kohle15.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.kohle16.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.kohle17.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.kohle18.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.kohle19.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.kohle20.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.kohle21.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.kohle22.childImageSharp.fluid} /></PageItem>
        {/* <PageItem  ><Img fluid={props.data.kohle23.childImageSharp.fluid} /></PageItem> */}



{/* <div>item 1</div>
  <div>item 2</div>
  <div>item 3</div>
  <div>item 4</div>
  <div>item 5</div>
  <div>item 6</div>
  <div>item 7</div>
  <div>item 8</div> */}










        {/* <div className="page 01">page01</div>
      <div className="page 02">page02</div> */}
      {/* </div> */}
    </div>
  </Layout>
)

export default SecondPage

export const fluidImage = graphql`
fragment fluidImage on File {
  childImageSharp {
    fluid(maxWidth: 1000) {
      ...GatsbyImageSharpFluid
    }
  }
}
`;

export const pageQuery = graphql`
  query {
    kohle01: file(relativePath: { eq: "kohle_1.jpg" }) {
      ...fluidImage
    }
    kohle02: file(relativePath: { eq: "kohle_2.jpg" }) {
      ...fluidImage
    }
    kohle03: file(relativePath: { eq: "kohle_3.jpg" }) {
      ...fluidImage
    }
    kohle04: file(relativePath: { eq: "kohle_4.jpg" }) {
      ...fluidImage
    }
    kohle05: file(relativePath: { eq: "kohle_5.jpg" }) {
      ...fluidImage
    }
    kohle06: file(relativePath: { eq: "kohle_6.jpg" }) {
      ...fluidImage
    }
    kohle07: file(relativePath: { eq: "kohle_7.jpg" }) {
      ...fluidImage
    }
    kohle08: file(relativePath: { eq: "kohle_8.jpg" }) {
      ...fluidImage
    }
    kohle09: file(relativePath: { eq: "kohle_9.jpg" }) {
      ...fluidImage
    }
    kohle10: file(relativePath: { eq: "kohle_10.jpg" }) {
      ...fluidImage
    }
    kohle11: file(relativePath: { eq: "kohle_11.jpg" }) {
      ...fluidImage
    }
    kohle12: file(relativePath: { eq: "kohle_12.jpg" }) {
      ...fluidImage
    }
    kohle13: file(relativePath: { eq: "kohle_13.jpg" }) {
      ...fluidImage
    }
    kohle14: file(relativePath: { eq: "kohle_14.jpg" }) {
      ...fluidImage
    }
    kohle15: file(relativePath: { eq: "kohle_15.jpg" }) {
      ...fluidImage
    }
    kohle16: file(relativePath: { eq: "kohle_16.jpg" }) {
      ...fluidImage
    }
    kohle17: file(relativePath: { eq: "kohle_17.jpg" }) {
      ...fluidImage
    }
    kohle18: file(relativePath: { eq: "kohle_18.jpg" }) {
      ...fluidImage
    }
    kohle19: file(relativePath: { eq: "kohle_19.jpg" }) {
      ...fluidImage
    }
    kohle20: file(relativePath: { eq: "kohle_20.jpg" }) {
      ...fluidImage
    }
    kohle21: file(relativePath: { eq: "kohle_21.jpg" }) {
      ...fluidImage
    }
    kohle22: file(relativePath: { eq: "kohle_22.jpg" }) {
      ...fluidImage
    }
    kohle23: file(relativePath: { eq: "kohle_23.jpg" }) {
      ...fluidImage
    }

  }
`